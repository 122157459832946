import type React from 'react'

import { styled, Tabs } from '@mui/material'

type TabProps = Parameters<typeof Tabs>[0]

const StyledTabs = styled((props: TabProps) => <Tabs {...props} />)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#6366f1',
  },
  borderBottom: '1px solid #e8e8e8',
  variant: 'standard',
})

export default StyledTabs
