import React from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import {
  createTheme,
  IconButton as MuiIconButton,
  IconButtonProps,
  ThemeProvider,
} from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    success: {
      main: '#16a34a',
    },
    error: {
      main: '#ef4444',
    },
  },
})

interface LibraryIconButtonProps extends IconButtonProps {
  className?: string
  loading?: boolean
  href?: string
  target?: string
}

const IconButton = ({
  loading,
  className,
  ...otherProps
}: LibraryIconButtonProps) => (
  <ThemeProvider theme={theme}>
    {loading ? (
      <MuiIconButton disabled {...otherProps} className={className}>
        <ArrowPathIcon className="h-5 w-5 animate-spin stroke-2" />
      </MuiIconButton>
    ) : (
      <MuiIconButton
        disableRipple
        {...otherProps}
        className={`${className} hover:bg-indigo-100`}
      />
    )}
  </ThemeProvider>
)

export default IconButton
